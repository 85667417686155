import React, {useState} from "react";
import style from './customButton.module.scss';
import cx from "classnames";

type CustomButtonPropsType = {
    name: string,
    type: 'main' | 'trial' | 'submit' | 'signIn',
    onAction?: () => void
}
export const CustomButton = ({name, type, onAction}: CustomButtonPropsType) => {

    return (
        <button className={cx({
            [style.custom_button]: true,
            [style.main_button]: type === 'main' || type === 'submit',
            [style.trial_button]: type === 'trial',
        })} onClick={onAction}>{name}</button>
    )
}
import React from "react";
import style from './errorPage.module.scss';
import {useRouteError} from "react-router-dom";
import warning from './../../../assets/images/warning.png';
import {CustomButton} from "../../parts/customButton/customButton";
import cx from "classnames";
import {useMobile, useMobileMini} from "../../../hooks/useAdaptives";

export const ErrorPage = () => {
    const error: any = useRouteError();
    let isMobile = useMobile();
    let isMobileMini = useMobileMini();

    return (
        <div id="error-page" className={style.error_page}>
            <h1 className={cx({
                ['heading_1']: !(isMobile || isMobileMini),
                ['heading_1_mini']: (isMobile || isMobileMini),
            })}>Oops!</h1>
            <div className={style.error_page_image}>
                <img src={warning} alt=""/>
            </div>
            <p className={style.error_page_sorry}>Sorry, an unexpected error has occurred.</p>
            {error ? <p className={style.error_page_message}>
                <i>{error.statusText || error.message}</i>
            </p> : null}
            <p className={style.error_page_please}>Please go to home page</p>
            <a href={'/'}>
                <CustomButton name={'Home'} type={'main'}/>
            </a>
        </div>
    )
}


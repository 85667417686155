import React from "react";
import style from './link.module.scss';
import {NavLink} from "react-router-dom";

export type LinkSitemapPropsType = {
    to: string,
    name: string,
    onLinkCLick?: () => void,
}
export const LinkSitemap = ({to, name, onLinkCLick}: LinkSitemapPropsType) => {

    return (
        <div className={style.sitemap_link}>
            <NavLink to={to} onClick={onLinkCLick}
                     className={({isActive}) => [isActive ? style.active_link : ''].join('')}>
                {name}
            </NavLink>
        </div>
    )
}
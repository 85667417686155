import { useMediaQuery } from 'react-responsive'

export const useDesktop = (): boolean => useMediaQuery({ minWidth: 1440 })

export const useDesktopMini = (): boolean =>
  useMediaQuery({ maxWidth: 1439, minWidth: 1201})

export const useLaptop = (): boolean =>
    useMediaQuery({ maxWidth: 1200, minWidth: 1025 })

export const useLaptopMini = (): boolean =>
  useMediaQuery({ maxWidth: 1024, minWidth: 769 })

export const useMobile = (): boolean =>
  useMediaQuery({ maxWidth: 768, minWidth: 577 })

export const useMobileMini = (): boolean =>
    useMediaQuery({ maxWidth: 576 })

export const useMobileExtraMini = (): boolean =>
    useMediaQuery({ maxWidth: 360 })
export const useLandscape = (): boolean =>
  useMediaQuery({ query: '(orientation: landscape)' })

import React from "react";
import style from './insertedImage.module.scss';

export type InsertedImagePropsType = {
    width: number,
    height: number,
    src: string,
    alt: string,
    cursor?: string,
    onClick?: () => void
}
export const InsertedImage = React.memo(function InsertedImage({
                                                                   width,
                                                                   height,
                                                                   src,
                                                                   alt,
                                                                   cursor,
                                                                   onClick
                                                               }: InsertedImagePropsType) {

    return (
        <div className={style.wrapper}
             onClick={onClick}
             style={{width: `${width}rem`, height: `${height}rem`, cursor: cursor}}>
            <img src={src} alt={alt}/>
        </div>
    )
})
import React from "react";
import style from './header.module.scss';
import {InsertedImage} from "../insertedImage/insertedImage";
import logo from './../../../assets/images/header/logo.png';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {CustomButton} from "../customButton/customButton";
import {useMobileMini} from "../../../hooks/useAdaptives";
import {MobileMenu} from "./mobileMenu/mobileMenu";
import burger from './../../../assets/images/header/burger.png';

type HeaderPropsType = {
    menuIsOpen: boolean,
    showMenu: () => void
}
export const Header = React.memo(({menuIsOpen, showMenu}: HeaderPropsType) => {
    let isMobileMini = useMobileMini();
    let navigate = useNavigate();
    let location = useLocation();

    const goHome = () => {
        navigate('/')
    }

    const links = [
        {index: 1, href: '/product', linkName: 'Product'},
        {index: 2, href: '/about', linkName: 'About Us'},
        {index: 3, href: '/contact', linkName: 'Contact Us'},
        {index: 4, href: '/blog', linkName: 'Blog'}
    ]

    return (
        <header className={style.header}>
            <div className={`${style.header_context_wrapper} center`}
                 style={{paddingBottom: ['/', '/about'].includes(location.pathname) && isMobileMini ? '1.063rem' : ''}}>
                <div className={style.context_wrapper}>
                    <InsertedImage width={isMobileMini ? 4.688 : 7.125}
                                   height={isMobileMini ? 1.563 : 2.375} src={logo} alt={'logo'} onClick={goHome}
                                   cursor={'pointer'}/>
                    {!isMobileMini ? <nav className={style.navigation}>
                            {links.map((l, index) => <div className={style.link_ease_in_out} key={index}><NavLink
                                to={l.href}
                                className={({isActive}) => [isActive ? style.active_link : ''].join('')}>{l.linkName}</NavLink>
                            </div>)}
                            <a className={style.trial_link} href={'https://enterprise.getunboxt.com/signUp'}>
                                <CustomButton name={'Start Trial'}
                                              type={'trial'}/>
                            </a>
                            <a className={style.trial_link} href={'https://enterprise.getunboxt.com/signIn'}>
                                <CustomButton name={'Sign In'}
                                              type={'trial'}/>
                            </a>
                        </nav>
                        : <div className={style.burger} onClick={showMenu}><img src={burger} alt="menu"/></div>
                    }

                    {isMobileMini && menuIsOpen && <MobileMenu showMenu={showMenu}/>}
                </div>

                {['/', '/about'].includes(location.pathname) ? null : <hr/>}
            </div>
        </header>
    )
})
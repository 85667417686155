import React from "react";
import style from './mobileMenu.module.scss';
import {NavLink} from "react-router-dom";
import {InsertedImage} from "../../insertedImage/insertedImage";
import logo from "../../../../assets/images/header/logo.png";
import close from "../../../../assets/images/header/mynaui_x.png";

type MobileMenuPropsType = {
    showMenu: () => void
}
export const MobileMenu = ({showMenu}: MobileMenuPropsType) => {

    const mobileLinks = [
        {index: 1, href: "/", linkName: 'Home'},
        {index: 2, href: "/product", linkName: 'Product'},
        {index: 3, href: "/about", linkName: 'About Us'},
        {index: 4, href: "/contact", linkName: 'Contact Us'},
        {index: 5, href: "/blog", linkName: 'Blog'},
    ]

    return (
        <div className={style.menu}>
            <div className={style.block_with_logo}>
                <InsertedImage width={7.125} height={2.375} src={logo} alt={'logo'}/>
                <InsertedImage width={2} height={2} src={close} alt={'close'} cursor={'pointer'} onClick={showMenu}/>
            </div>
            <nav className={style.navigation}>
                {mobileLinks.map((l, index) => <NavLink key={index}
                                                        to={l.href}
                                                        onClick={showMenu}
                                                        className={({isActive}) => [isActive ? style.active_link : ''].join('')}>{l.linkName}</NavLink>)}
            </nav>
        </div>
    )
}
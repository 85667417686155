import React from 'react';
import './App.scss';
import {Outlet} from "react-router-dom";
import {Header} from "./components/parts/header/header";
import {Footer} from "./components/parts/footer/footer";

function App() {
    const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
    const showMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    }

    return (
        <div className={'app_wrapper'}>
            <Header menuIsOpen={menuIsOpen} showMenu={showMenu}/>
            <div className={'main_content_wrapper'}>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;

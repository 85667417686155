import React from "react";
import style from './contactBlock.module.scss';
import {InsertedImage} from "../../insertedImage/insertedImage";
import contact from "../../../../assets/images/footer/contact.png";
import {useMobileMini} from "../../../../hooks/useAdaptives";

export const ContactBlock = () => {
    const isMobileMini = useMobileMini();

    return (
        <div className={style.contact_block}>
            <div className={style.block_with_title}>
                <div className={style.title}>CONTACT</div>
                <InsertedImage width={isMobileMini ? 0.75 : 1.063} height={isMobileMini ? 0.75 : 1.063} src={contact}
                               alt={'contact'}/>
            </div>
            <div className={style.separator}></div>
            <div className={style.address}>
                2605 Circle 75 Parkway, Atlanta, GA 30339
            </div>
            <div className={style.email}>
                <div></div>
                <a href="mailto:getunboXt@getunboXt.com">getunboXt@getunboXt.com</a>
            </div>
            <div className={style.phone}>
                <div></div>
                <a href="tel:+4708002901">+470-800-2901</a>
            </div>
        </div>
    )
}
import React from "react";
import style from './footer.module.scss';
import {SocialMediaBlock} from "./socialMediaBlock/sochialMediaBlock";
import {SitemapBlock} from "./sitemapBlock/sitemapBlock";
import {ContactBlock} from "./contactBlock/contactBlock";
import {NavLink} from "react-router-dom";

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className={style.footer}>
            <div className={`${style.footer_context_wrapper} center`}>
                <SocialMediaBlock/>
                <SitemapBlock/>
                <ContactBlock/>
                <div className={style.copyright}>
                    © {year} UNBOXT Pvt. Ltd.
                    &nbsp; | &nbsp;
                    <NavLink to={"privacyPolicy"}>privacy policy</NavLink>
                    &nbsp; | &nbsp;
                    <NavLink to={"cookiesPolicy"}>cookies policy</NavLink>
                </div>
            </div>
        </footer>
    )
}
import React from "react";
import style from './socialMedia.module.scss';
import linkedin from './../../../../assets/images/footer/linkedin.png';
import twitter from './../../../../assets/images/footer/twitter.svg';
import facebook from './../../../../assets/images/footer/facebook-white.png';
import {InsertedImage} from "../../insertedImage/insertedImage";
import {useMobile, useMobileMini} from "../../../../hooks/useAdaptives";
import smile from './../../../../assets/images/footer/smile1.png';

export type IconLinkType = {
    width: number,
    height: number,
    src: string,
    class: string,
    alt: string,
    href: string,
}
export const SocialMediaBlock = () => {
    const isMobileMini = useMobileMini();
    const isMobile = useMobile();

    const icons: Array<IconLinkType> = [
        {width: isMobileMini ? 0.93 : 1.321, height: isMobileMini ? 0.93 : 1.321, src: facebook, class: 'facebook_icon', alt: 'facebook', href: 'https://www.facebook.com/getunboxt'},
        {width: isMobileMini ? 0.93 : 1.321, height: isMobileMini ? 0.93 : 1.321, src: linkedin, class: 'linkedin_icon', alt: 'linkedin', href: 'https://www.linkedin.com/company/unboxt-inc/'},
        {width: isMobileMini ? 1.12 : 1.558, height: isMobileMini ? 0.9 : 1.266, src: twitter, class: 'twitter_icon', alt: 'twitter', href: 'https://twitter.com/GetUnboxt'}
    ]

    return (
        <div className={style.social_media_block}>
            <div className={style.social_media_block_part_with_title}>
                <div className={style.smile_img}>
                    <InsertedImage width={isMobile || isMobileMini ? 1 : 1.5}
                                   height={isMobile || isMobileMini ? .8 : 1.3} alt={'smile'} src={smile}/>
                </div>
                <div className={style.title}>Get in Touch</div>
            </div>
            <div className={style.social_media_block_part_with_icons}>
                {icons.map((i, index) => <a key={index} href={i.href} className={style[`${i.class}`]} style={{
                    width: `${i.width}rem`,
                    height: `${i.height}rem`,
                }}>
                    {/*<img src={i.src} alt={i.alt} />*/}
                </a>)}
            </div>

        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {ErrorPage} from "./components/pages/errorPage/errorPage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<App/>} errorElement={<ErrorPage/>}>
            <Route index lazy={async () => {
                let mod = await import('./../src/components/pages/homePage/homePage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.HomePage/>
                }
            }}/>
            <Route path={'product'} lazy={async () => {
                let mod = await import('./../src/components/pages/productPage/productPage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.ProductPage/>
                }
            }}/>
            <Route path={'about'} lazy={async () => {
                let mod = await import('./../src/components/pages/aboutPage/aboutPage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.AboutPage/>
                }
            }}/>
            <Route path={'contact'} lazy={async () => {
                let mod = await import('./../src/components/pages/contactPage/contactPage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.ContactPage/>
                }
            }}/>
            <Route path={'blog'} lazy={async () => {
                let mod = await import('./../src/components/pages/blogPage/blogPage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.BlogPage/>
                }
            }}>
                <Route path={`category/:category/page/:page`} lazy={async () => {
                    let mod = await import('./../src/components/pages/blogPage/certainPageContent/certainPageContent');
                    return {
                        ...mod,
                        Component: undefined,
                        element: <mod.CertainPageContent/>
                    }
                }}/>
            </Route>
            <Route path={'blog/:id'} lazy={async () => {
                let mod = await import('./../src/components/pages/postPage/postPage');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.PostPage/>
                }
            }}/>
            <Route path={'privacyPolicy'} lazy={async () => {
                let mod = await import('./../src/components/pages/privacyPolicy/privacyPolicy');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.PrivacyPolicy/>
                }
            }}/>
            <Route path={'cookiesPolicy'} lazy={async () => {
                let mod = await import('./../src/components/pages/cookiesPolicy/cookiesPolicy');
                return {
                    ...mod,
                    Component: undefined,
                    element: <mod.CookiesPolicy/>
                }
            }}/>
        </Route>
    )
)


root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

reportWebVitals();

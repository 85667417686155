import React from "react";
import style from './sitemapBlock.module.scss';
import {InsertedImage} from "../../insertedImage/insertedImage";
import sitemap from './../../../../assets/images/footer/sitemap.png';
import {LinkSitemap} from "./link/link";
import {useMobile, useMobileMini} from "../../../../hooks/useAdaptives";

type LinkSitemapType = {
    type: 'link',
    to: string,
    name: string,
}

export const SitemapBlock = () => {
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();

    const links: Array<LinkSitemapType | { type: 'separator' | 'nextLine' } | null> = [
        {type: 'link', to: '/', name: 'HOME'},
        {type: 'separator'},
        {type: 'link', to: '/product', name: 'PRODUCT'},
        {type: 'separator'},
        {type: 'link', to: '/blog', name: 'BLOG'},
        {type: 'link', to: '/about', name: 'ABOUT US'},
        {type: 'separator'},
        {type: 'link', to: '/contact', name: 'CONTACT'},
    ]

    let ind = links.indexOf(links.filter(l => l?.type === 'link' && l.name === 'BLOG')[0]);
    (isMobile || isMobileMini) && links.splice(ind + 1, 0, {type: 'separator'})

    return (
        <div className={style.sitemap_block}>
            <div className={style.block_with_title}>
                <div className={style.title}>SITEMAP</div>
                <InsertedImage width={isMobileMini ? 0.75 : 1.063} height={isMobileMini ? 0.75 : 1.063} src={sitemap}
                               alt={'sitemap'}/>
            </div>
            <div className={style.separator}></div>
            <div className={style.block_with_links}>
                {links.map((l, index) => {
                    return l?.type === 'link' ? <LinkSitemap key={index} to={l.to} name={l.name}/>
                        : l?.type === 'separator' ? <div key={index} className={style.vertical_separator}>|</div>
                            : <div key={index} style={{width: '100%', marginBottom: '0'}}></div>
                })}
            </div>
        </div>
    )
}